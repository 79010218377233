import { OrderType, iSupportedCurrency } from "../../../module/fiat/types";

export const ENDPOINTS = {
  auth: {
    signin: {
      method: "POST",
      url: "/no-auth/Account/Login",
    },

    signup: {
      method: "POST",
      url: "/no-auth/Account/Register",
    },
    send_otp: {
      method: "POST",
      url: "/no-auth/Account/SendOTP",
    },

    reset_password: {
      method: "POST",
      url: "/no-auth/Account/ResetPassword",
    },
    get_countries: {
      method: "GET",
      url: "/no-auth/Misc/GetCountries",
    },
    get_csrf_token: {
      method: "POST",
      url: "/no-auth/CSRF/GetToken",
    },
  },
  appstate: {
    onlineOffline: {
      method: "POST",
      url: (id: string) => `/Setting/GoOnlineOffline?user_id=${id}`,
    },
    language: {
      method: "POST",
      url: (id: string) => `/Setting/SaveExchangerLanguage?user_id=${id}`,
    },
    switch_multi_account: {
      method: "POST",
      url: "/Account/SwitchAccount",
    },
    notification: {
      get_notification_list: {
        method: "GET",
        url: (id: string) => `/Notification/List?user_id=${id}`,
      },
      read_notification: {
        method: "POST",
        url: "/Notification/Read",
      },
      read_all_notification: {
        method: "POST",
        url: "/Notification/ReadAll",
      },
    },
  },
  dashboard: {
    get_info: {
      method: "GET",
      url: (id: string) => `/Exchanger/DashboardStat?user_id=${id}`,
    },
    get_info_new: {
      method: "GET",
      url: (id: string) => `/Exchanger/GetInfo?user_id=${id}`,
    },
    get_incoming_settlements: {
      method: "GET",
      url: (id: string) => `/Settlement/Incoming?user_id=${id}`,
    },
    get_dispute_funds: {
      method: "GET",
      url: (id: string) => `/Dispute/Funds?user_id=${id}`,
    },
  },
  team: {
    list_team_member: {
      method: "GET",
      url: (id: string) => `/Team/List?user_id=${id}`,
    },
    create_team_member: {
      method: "POST",
      url: `/Team/CreateTeam`,
    },
    suspend_team_member: {
      method: "POST",
      url: `/Team/Suspend`,
    },
    unsuspend_team_member: {
      method: "POST",
      url: "/Team/UnSuspend",
    },
    updatae_team_member: {
      method: "POST",
      url: "/Team/UpdateTeam",
    },
  },
  transaction: {
    withdrawal: {
      method: "GET",
      url: (id: string) => `/Transaction/Withdrawal?user_id=${id}`,
    },
    funding: {
      method: "GET",
      url: (id: string) => `/Transaction/Funding?user_id=${id}`,
    },
    fund_wallet: {
      method: "POST",
      url: `/Transaction/FundWallet`,
    },
    withdraw_wallet: {
      method: "POST",
      url: (id: string) => `/Transaction/WithdrawFromWallet?user_id=${id}`,
    },
  },
  crypto_wallet: {
    capital: {
      method: "GET",
      url: (id: string) =>
        `/History/CapitalWallet?user_id=${id}&start_page=1&total_on_page=10`,
    },
    commission: {
      method: "GET",
      url: (id: string) =>
        `/History/CommissionWallet?user_id=${id}&start_page=1&total_on_page=10`,
    },
  },
  setting: {
    bank: {
      add_bank: {
        method: "POST",
        url: (id: string) => `/Bank/AddNewBank?user_id=${id}`,
      },
      list_all_banks: {
        method: "GET",
        url: (id: string) => `/Bank/ListExchangerBank?user_id=${id}`,
      },
      set_default_bank: {
        method: "POST",
        url: (id: string) => `/Bank/SetExchangerBankAsDefault?user_id=${id}`,
      },
      delete_bank: {
        method: "POST",
        url: (id: string) => `/Bank/DeleteExchangerBank?user_id=${id}`,
      },
    },
    withdrawal_integration: {
      save_withdrawal_integration: {
        method: "POST",
        url: (id: string) => `/WithdrawalAPI/SaveSettings?user_id=${id}`,
      },
      enable_withdrawal_integration: {
        method: "POST",
        url: (id: string) => `/WithdrawalAPI/EnableChannel?user_id=${id}`,
      },
    },
    settlement: {
      enable_settlement: {
        method: "POST",
        url: (id: string) => `/Settlement/Enable?user_id=${id}`,
      },
      set_payout_threshold: {
        method: "POST",
        url: (id: string) => `/Settlement/SetPayoutThreshold?user_id=${id}`,
      },
      add_settlement_wallet: {
        method: "POST",
        url: (id: string) => `/Settlement/AddSettlementWallet?user_id=${id}`,
      },
      get_settlment_address: {
        method: "GET",
        url: (id: string) => `/Settlement/GetSettlementAddresses?user_id=${id}`,
      },
      deactivate_settlment_address: {
        method: "POST",
        url: (id: string) =>
          `/Settlement/DeactivateSettlementAddress?user_id=${id}`,
      },
      activate_settlment_address: {
        method: "POST",
        url: (id: string) =>
          `/Settlement/ActivateSettlementAddress?user_id=${id}`,
      },
      download_wallet_certificate: {
        method: "POST",
        url: (id: string) => `/Settlement/DownloadRSA?user_id=${id}`,
      },
    },
  },
  performance: {
    chart: {
      method: "GET",
      url: (id: string, filterby: string) =>
        `/Chart/Data?user_id=${id}&filter_by=${filterby}`,
    },
    metric: {
      method: "GET",
      url: (id: string) => `/Performance/Data?user_id=${id}`,
    },
  },
  // exchange: {
  //   fund_wallet: {
  //     method: "POST",
  //     url: "/Wallet/Fund",
  //   },
  //   withdraw_wallet: {
  //     method: "POST",
  //     url: "/Wallet/Withdraw",
  //   },
  //   fetch_transaction: {
  //     method: "POST",
  //     url: "/no-auth/Transaction/Fetch",
  //   },
  //   webhook_transaction_update: {
  //     method: "POST",
  //     url: "/no-auth/Transaction/FundWithdrawUpdate",
  //   },
  // },
  payment: {
    create_wallet: {
      method: "POST",
      url: (id: string) =>
        `/PartnerP2P_API/SetUpCollectionWallet?user_id=${id}`,
    },
    upload_kyc: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/UploadKYC?user_id=${id}`,
    },
    register_merchant: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/MerchantOnBoard?user_id=${id}`,
    },
    payout_merchant: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/WithdrawFiat?user_id=${id}`,
    },
    generate_key: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/GenerateKey?user_id=${id}`,
    },
    toggleP2P: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/TurnOnOffP2P?user_id=${id}`,
    },
    get_bank_info_funding: {
      method: "GET",
      url: (id: string) => `/PartnerP2P_API/GetFundingInfo?user_id=${id}`,
    },
    get_P2P_transactions: {
      method: "GET",
      // url: (
      //   id: string,
      //   txn_type: string,
      //   order_type: OrderType,
      //   date_from?: string,
      //   date_to?: string,
      //   order_no?: string
      // ) =>
      //   `/PartnerP2P_API/Transactions?user_id=${id}&txn_type=${txn_type}${
      //     order_type !== "all" ? `&order_type=${order_type}` : ""
      //   }${order_no ? `&order_no=${order_no}` : ""}${
      //     date_from && date_to
      //       ? `&date_from=${date_from}&date_to=${date_to}`
      //       : ""
      //   }`,
      url: (query?: string) => `/PartnerP2P_API/Transactions${query}`,
    },
    get_P2P_collection_detail: {
      method: "GET",
      url: (
        id: string,
        txn_type: string,
        collection_id: string,
        order_no: string,
        bank_ref: string,
        currency: string
      ) =>
        `/PartnerP2P_API/GetTransactions?user_id=${id}&txn_type=${txn_type}${
          order_no ? `&order_no=${order_no}` : ""
        }${collection_id ? `&collection_id=${collection_id}` : ""}${
          bank_ref ? `&bank_ref=${bank_ref}` : ""
        }${currency ? `&currency=${currency}` : ""}`,
    },
    claim_collection: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/ClaimFunds?user_id=${id}`,
    },
    claim_all_funds: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/ClaimAllFunds?user_id=${id}`,
    },
    auto_claim: {
      method: "POST",
      url: (id: string) =>
        `/PartnerP2P_API/EnableDisableAutoClaim?user_id=${id}`,
    },
    get_transaction_by_order_no: {
      method: "GET",
      url: (id: string, txn_type: string, order_no: string) =>
        `/PartnerP2P_API/QueryTransactionsByOrderNo?user_id=${id}&txn_type=${txn_type}&order_no=${order_no}`,
    },
    filter_tranasction_by_order_no: {
      method: "POST",
      url: (id: string) =>
        `/PartnerP2P_API/QueryTransactionsByDate?user_id=${id}`,
    },

    get_P2P_merchant_info: {
      method: "GET",
      url: (id: string) => `/PartnerP2P_API/GetMerchantInfo?user_id=${id}`,
    },
    get_P2P_partners: {
      method: "GET",
      url: (id: string) => `/PartnerP2P_API/ListPartners?user_id=${id}`,
    },
    get_banks_by_country_currency: {
      method: "GET",
      url: (id: string, currency: iSupportedCurrency) =>
        `/PartnerP2P_API/AllBanks?user_id=${id}&currency=${currency}`,
    },
    resolve_payment_withdrawal: {
      method: "POST",
      url: (id: string) =>
        `/PartnerP2P_API/ResolveBankAccountName?user_id=${id}`,
    },
    payment_withdrawal: {
      method: "POST",
      url: (id: string) => `/PartnerP2P_API/InitiatePayout?user_id=${id}`,
    },
    swap: {
      get_exhange_rate: {
        method: "GET",
        url: (id: string, from: string, to: string) =>
          `/PartnerP2P_API/P2pExchangeData?user_id=${id}&from_currency=${from}&to_currency=${to}`,
      },
      swap_funds: {
        method: "POST",
        url: (id: string) => `/PartnerP2P_API/SwapMyFund?user_id=${id}`,
      },
    },
  },
  pool: {
    issues: {
      all: {
        method: "GET",
        url: (id: string) => `/Pool/ListIssues?user_id=${id}`,
      },
      send_issue_request: {
        method: "POST",
        url: "/Pool/NewIssueChat",
      },
      chat_issue_thread: {
        method: "GET",
        url: (user_id: string, txn_ref: string) =>
          `/Pool/GetsIssues?user_id=${user_id}&txn_reference=${txn_ref}`,
      },
    },
    tray: {
      accept: {
        method: "POST",
        url: `/Pool/AcceptTray`,
      },
      ignore: {
        method: "POST",
        url: `/Pool/IgnoreTray`,
      },
    },
    pending_request: {
      get_pending_request: {
        method: "GET",
        url: (id: string) => `/Pool/PendingRequest?user_id=${id}`,
      },
      fund_pr: {
        method: "POST",
        url: "/Pool/CompleteTray",
      },
    },
    processing_request: {
      get_processing_request: {
        method: "GET",
        url: (id: string) => `/Pool/ProcessingRequest?user_id=${id}`,
      },
      upload_payment_evidence: {
        method: "POST",
        url: "/Proof/UploadEvidence",
      },
    },

    junk_request: {
      get_junk_request: {
        method: "GET",
        url: (id: string) => `/Pool/JunkList?user_id=${id}`,
      },
      move_to_junk: {
        method: "POST",
        url: "/Pool/MovedToJunk",
      },
    },

    completed_request: {
      method: "GET",
      url: (id: string) => `/Pool/CompletedRequest?user_id=${id}`,
    },
  },
  non_auth: {
    check_status: {
      method: "GET",
      url: (id: string) => `/no-auth/P2pAuthorize/CheckStatus?tk=${id}`,
    },
    authorize_payment: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/MakePayment",
    },
    finalize_ghs: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/FinalizeGhsCollection",
    },
    kenya_otp: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/SendKenyaOTP",
    },
    verify_kenya_otp: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/VerifyKenyaOTP",
    },
    ghana_otp: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/ResendGhanaOTP",
    },
    kenya_phone_verified_on_remit: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/IsKenyaPhoneVerifiedOnRemit",
    },
    payment_status: {
      method: "GET",
      url: (id: string) => `/no-auth/P2pAuthorize/PaymentStatus?tk=${id}`,
    },
    mobile_money: {
      method: "GET",
      url: "/no-auth/PartnerP2P/MobileMoney",
    },
    resolve_account: {
      method: "POST",
      url: "/no-auth/Verification/Verify",
    },
    create_facial_stamp: {
      method: "POST",
      url: "/no-auth/P2pAuthorize/CreateFacialStamp",
    },
    get_bank_portal_link: {
      method: "GET",
      url: "/no-auth/P2pAuthorize/GetBankPortalLink",
    },
    get_south_african_bank: {
      method: "GET",
      url: "/no-auth/P2pAuthorize/GetAllSouthAfricaBanks",
    },
  },
};
