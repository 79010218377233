import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
} from "../../../common/service/request";
import { queryHandler } from "../../../common/utils/helpers";
import * as tp from "../types";

const service = {
  async createWallet(
    data: tp.IcreateWalletThunk
  ): Promise<tp.APIResponseModel<tp.IpaymentWallet>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.create_wallet;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async uploadKyc(data: any): Promise<tp.APIResponseModel<null>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.upload_kyc;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async registerMerchant(
    data: tp.IcreateMerchantThunk
  ): Promise<tp.APIResponseModel<null>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.register_merchant;
    const url = makeUrl(data.user_id);

    const indata = {
      partner_merchant_id: data.partner_merchant_id,
      merchant_callback_url: data.merchant_callback_url,
      partner_id: data.partner_id,
    };
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, indata);
  },
  async payoutMerchant(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.payout_merchant;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async generateKey(
    data: tp.IgenerateSecretKeyThunk
  ): Promise<tp.APIResponseModel<tp.IgenerateSecretKeyRes>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.generate_key;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async toggleP2P(data: tp.ItoggleP2PThunk): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.toggleP2P;
    const { partner_code, status, user_id } = data;
    const url = makeUrl(user_id);
    let inData = { partner_code, status };

    return makeAuthorizedRequestWithHeadersAndPayload(method, url, inData);
  },
  async getBankInfoFunding(
    id: string
  ): Promise<tp.APIResponseModel<tp.IpaymentWallet>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_bank_info_funding;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getP2PTransactions(
    req: tp.IpaymentCategoryThunk
  ): Promise<tp.APIResponseModel<tp.Itransactions>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_P2P_transactions;

    let params = "";

    const keys = Object.keys(req) as Array<keyof typeof req>;
    keys.forEach((key) => {
      if (req[key]) {
        if (params.length === 0) {
          params += "?";
        } else {
          params += "&";
        }

        params += `${key}=${req[key]}`;
      }
    });

    const url = makeUrl(params);

    // const { order_type, txn_type, user_id, date_from, date_to, order_no } = req;
    // const url = makeUrl(
    //   user_id,
    //   txn_type,
    //   order_type,
    //   date_from,
    //   date_to,
    //   order_no
    // );
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getCollectionDetail(
    id: string,
    txn_type: tp.IpaymentCategory,
    collection_id: string,
    order_no: string,
    bank_ref: string,
    currency: string
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.payment.get_P2P_collection_detail;
    const url = makeUrl(
      id,
      txn_type,
      collection_id,
      order_no,
      bank_ref,
      currency
    );
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async claimCollectionFunds(
    id: string,
    order_no: string
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.claim_collection;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, {
      order_no,
    });
  },
  async claimAllFunds(params: tp.Iuser): Promise<tp.APIResponseModel<any>> {
    const { user_id } = params;
    const { method, url: makeUrl } = ENDPOINTS.payment.claim_all_funds;
    const url = makeUrl(user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async autoClaim(
    id: string,
    status: boolean
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.auto_claim;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, { status });
  },
  async getTransactionByOrderNo(
    params: tp.ISearchTransactionOrderThunk
  ): Promise<tp.APIResponseModel<any>> {
    const { order_no, txn_type, user_id } = params;
    const { method, url: makeUrl } =
      ENDPOINTS.payment.get_transaction_by_order_no;
    const url = makeUrl(user_id, txn_type, order_no);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async filterTransactionByOrderNo(
    params: tp.IFilterTransactionOrderThunk
  ): Promise<tp.APIResponseModel<any>> {
    const { data, user_id } = params;
    const { method, url: makeUrl } =
      ENDPOINTS.payment.filter_tranasction_by_order_no;
    const url = makeUrl(user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async getMerchantInfo(
    id: string
  ): Promise<tp.APIResponseModel<tp.ImerchantInfo>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_P2P_merchant_info;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getPartners(
    id: string
  ): Promise<tp.APIResponseModel<tp.IpartnerInfo[]>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.get_P2P_partners;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async checkStatus(tk: string): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.non_auth.check_status;
    const url = makeUrl(tk);
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, { tk });
  },
  async authorizePayment(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.authorize_payment;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async isKenyaPhoneVerifiedOnRemit(
    data: any
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.kenya_phone_verified_on_remit;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async kenyaPaymentOtp(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.kenya_otp;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async verifyKenyatOtp(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.verify_kenya_otp;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async ghanaPaymentOtp(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.ghana_otp;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async finalizeGhs(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.finalize_ghs;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async paymentStatus(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.non_auth.payment_status;
    const url = makeUrl(data.tk);
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async mobileMoneyInfo(): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.mobile_money;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, {});
  },
  async resolveAccount(
    data: tp.IresolveAccount
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.resolve_account;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getAllBanks({
    currency,
    user_id,
  }: tp.IallBanksThunk): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.payment.get_banks_by_country_currency;
    const url = makeUrl(user_id, currency);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async resolveAccountByCurrency(
    data: tp.IresolveAccountByCurrencyThunk
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.payment.resolve_payment_withdrawal;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async paymentWithdrawal(
    data: tp.IwithDrawalPaymentThunk
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.payment_withdrawal;
    const url = makeUrl(data.user_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getFiatExchangeRate({
    user_id,
    fiat_currency,
    type,
  }: tp.IexchangeQueryThunk): Promise<
    tp.APIResponseModel<tp.IexchangeQueryRes>
  > {
    const { method, url: makeUrl } = ENDPOINTS.payment.swap.get_exhange_rate;
    let from_currency = fiat_currency;
    let to_currency = "USDT";
    if (type === "TO_FIAT") {
      from_currency = "USDT";
      to_currency = fiat_currency;
    }
    const url = makeUrl(user_id, from_currency, to_currency);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async swapFunds(data: tp.IswapFundsThunk): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.payment.swap.swap_funds;
    const { user_id } = data;
    const url = makeUrl(user_id);
    const userData: any = { ...data };
    delete userData.user_id;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, userData);
  },
  async createFacialStamp(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.create_facial_stamp;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getBankPortalLink(data: any): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.non_auth.get_bank_portal_link;
    const query = queryHandler(data);
    const url = makeUrl + query;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, {});
  },
  async getSouthAfricanBank(): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.non_auth.get_south_african_bank;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, {});
  },
};

export default service;
